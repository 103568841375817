import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-services-section',
    templateUrl: 'services-section.component.html',
    styleUrls: ['services-section.component.scss']
})

export class ServicesSectionComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ContactFormService {

    constructor(private httpClient: HttpClient) {

    }

    async sendNotification(name: string, email: string, title: string, message: string) {
        await this.httpClient.post(`${environment.apiURL}/contactform`, {
            name: name,
            email: email,
            title: title,
            message: message
        }).toPromise();
    }

}
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-technologies-section',
    templateUrl: 'technologies-section.component.html',
    styleUrls: ['technologies-section.component.scss']
})

export class TechnologiesSectionComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}
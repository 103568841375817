import { AfterViewInit, Component, ComponentFactoryResolver, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StructureSection } from 'src/app/models/json-data-structure/structure-section';
import { ISectionComponent } from '../../models/isection-component';
import { SectionDesignComponentResolver } from '../../services/decorator.service';


@Component({
    selector: 'ss-site-section',
    templateUrl: './site-section.component.html',
    styleUrls: ['./site-section.component.scss']
})
export class SSSiteSectionComponent implements OnInit, AfterViewInit {
    @Input()
    section: StructureSection;

    @ViewChild('formTemplate', { read: ViewContainerRef }) formRef: ViewContainerRef;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver
    ) { }

    ngAfterViewInit(): void {
        let c = this.componentFactoryResolver.resolveComponentFactory<ISectionComponent>(SectionDesignComponentResolver.getComponent(this.section.designStyle));
        let template = this.formRef.createComponent(c);
        template.instance.section = this.section;
    }

    ngOnInit(): void {

    }


}

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StructureSection } from 'src/app/models/json-data-structure/structure-section';
import { SectionDesignComponent } from 'src/app/section-structures/decorators/section-design-style.decorator';

declare var gtag: Function;

@Component({
    selector: 'ss-small-landing',
    templateUrl: './small-landing.component.html',
    styleUrls: ['./small-landing.component.scss'],
    // encapsulation: ViewEncapsulation.,
})
@SectionDesignComponent("small-landing")
export class SSSmallLandingComponent implements OnInit {
    @Input()
    section: StructureSection;

    constructor() { }

    ngOnInit(): void { }

    generateConversion() {
        console.log("GTAG", gtag);
        gtag('event', 'conversion', {
            'send_to': 'AW-11253295981/PsHhCMfNmb4YEO3W_vUp',
        });
    }
}

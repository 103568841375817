<div *ngIf="section" [id]="section.id" class="wow fadeIn" data-wow-delay="0.3s">

    <ng-template #formTemplate></ng-template>

    <!-- <ss-common-landing *ngIf="section.designStyle == 'landing-1'" [section]="section"></ss-common-landing> -->

    <!-- <div class="container" *ngIf="section.designStyle == 'cards-1'">
        <h1 class="text-center mb-5 mt-5">{{section.title}}</h1>
        {{section.subtitle}}
        <ss-cards-background [childSections]="section.childSections" [colSize]="4"></ss-cards-background>
    </div> -->
</div>
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StructureSection } from 'src/app/models/json-data-structure/structure-section';
import { SectionDesignComponent } from 'src/app/section-structures/decorators/section-design-style.decorator';

@Component({
    selector: 'ss-common-landing',
    templateUrl: './common-landing.component.html',
    styleUrls: ['./common-landing.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
@SectionDesignComponent("landing-1")
export class SSCommonLandingComponent implements OnInit {
    @Input()
    section: StructureSection;

    constructor() { }

    ngOnInit() { 
        this.section;
    }

    mobileSize() {
        return document.body.clientWidth < 768;
    }
}

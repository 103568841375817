<!--Main Navigation-->
<!-- Intro Section -->
<div *ngIf="section" class="view jarallax" data-jarallax='{"speed": 0.2}' [ngClass]="{'height100vh': !mobileSize()}"
    style="background-image: url(/assets/landing.jpeg); background-size: cover;">
    <div class=" full-bg-img rgba-black-light" [ngClass]="{'mask': !mobileSize()}">
        <div class=" container flex-center" style="padding-bottom: 20px !important;">
            <div class="row pt-5 mt-3 w-100" style="padding-bottom: 20px !important;">
                <div class="col-md-12 text-center">
                    <h2 class="h1 h1-reponsive white-text font-up font-bold mb-3 wow fadeInDown" data-wow-delay="0.3s">
                        <strong>{{section.title}}</strong>
                    </h2>
                    <hr class="hr-light mt-4 wow fadeIn" data-wow-delay="0.4s">
                    <h5 class="font-up mb-5 white-text wow fadeInDown" data-wow-delay="0.4s">
                        <strong>{{section.subtitle}}</strong>
                    </h5>
                </div>
                <div class="col-12 text-white text-center wow fadeInDown"
                    style="padding: 20px; border-radius: 10px; background-color: rgba(0, 0, 0, 0.616);"
                    [innerHTML]="section.content">
                </div>
            </div>
        </div>
    </div>
</div>
<!--Main Navigation-->
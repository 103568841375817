import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cards-section',
    templateUrl: 'cards-section.component.html',
    styleUrls: ['cards-section.component.scss']
})

export class CardsSectionComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}
<div class="row">
    <div class="col-12 text-center wow fadeIn">
        <app-section-title title="NUESTROS SERVICIOS">
        </app-section-title>
    </div>
</div>
<div class="row">
    <div class="col-xl-3 col-md-6  wow fadeIn">
        <!-- Card -->
        <mdb-card style="background-image: url('./assets/Carta-seleccion-de-personal.jpg')"
            class="card-image mb-3 card-info">
            <!-- Content -->
            <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4">
                <div>
                    <h5 class="text-primary">
                        <mdb-icon fas icon="sitemap"></mdb-icon>
                        EMPRESAS
                    </h5>
                    <h3 class="card-title pt-2"><strong>Selección de Personal</strong></h3>
                    <p>
                        Gestionamos el proceso de selección y encontramos los mejores colaboradores para tu
                        organización.
                        Brindamos un servicio rápido y confiable.
                    </p>

                </div>
            </div>
            <!-- Content -->
        </mdb-card>
        <!-- Card -->
    </div>
    <div class="col-xl-3 col-md-6  wow fadeIn">
        <!-- Card -->
        <mdb-card style="background-image: url('./assets/carta-curriculum.jpg')" class="card-image mb-3 card-info">
            <!-- Content -->
            <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4">
                <div>
                    <h5 class="text-primary">
                        <mdb-icon fas icon="sitemap"></mdb-icon>
                        PERSONAS
                    </h5>
                    <h3 class="card-title pt-2"><strong>Armamos tu CV</strong></h3>
                    <p>
                        Tu Curriculum Vitae será moderno, eficaz y de fácil lectura. Llamará la atención de los
                        selectores
                        de personal. De esta manera accederás a mayor cantidad de entrevistas y podrás demostrar todo tu
                        potencial.
                    </p>

                </div>
            </div>
            <!-- Content -->
        </mdb-card>
        <!-- Card -->
    </div>
    <div class="col-xl-3 col-md-6 wow fadeIn">
        <!-- Card -->
        <mdb-card style="background-image: url('./assets/carta-entrevista.jpg')" class="card-image mb-3  card-info ">
            <!-- Content -->
            <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4">
                <div>
                    <h5 class="text-primary">
                        <mdb-icon fas icon="sitemap"></mdb-icon>
                        PERSONAS
                    </h5>
                    <h3 class="card-title pt-2"><strong>Simulamos tu Entrevista</strong></h3>
                    <p>
                        Hacemos simulacros de entrevistas laborales para evaluar tu desempeño y darte el feedback que
                        necesitas para encontrar tu lugar en el mundo laboral.
                    </p>
                    <h6><small>(MODALIDAD ONLINE)</small></h6>
                </div>
            </div>
            <!-- Content -->
        </mdb-card>
        <!-- Card -->
    </div>
    <div class="col-xl-3 col-md-6 wow fadeIn">
        <!-- Card -->
        <mdb-card style="background-image: url('./assets/carta-foda.jpg')" class="card-image mb-3 card-info">
            <!-- Content -->
            <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4">
                <div>
                    <h5 class="text-primary">
                        <mdb-icon fas icon="sitemap"></mdb-icon>
                        PERSONAS
                    </h5>
                    <h3 class="card-title pt-2"><strong>Hacemos tu FODA</strong></h3>
                    <p>
                        Te ayudamos a hacer tu análisis FODA para identificar tus fortalezas y tus áreas de mejora.
                        Conocerte es la mejor herramienta para conseguir tu empleo ideal.
                    </p>
                    <h6><small>(MODALIDAD ONLINE)</small></h6>
                </div>
            </div>
            <!-- Content -->
        </mdb-card>
        <!-- Card -->
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { StructureSection } from 'src/app/models/json-data-structure/structure-section';
import { SectionDesignComponent } from 'src/app/section-structures/decorators/section-design-style.decorator';
import { ISectionComponent } from 'src/app/section-structures/models/isection-component';

@Component({
    selector: 'ss-card-simple',
    templateUrl: './card-simple.component.html',
    styleUrls: ['./card-simple.component.scss']
})
@SectionDesignComponent("card-simple")
export class SSCardSimpleComponent implements OnInit, ISectionComponent {
    @Input()
    section: StructureSection;

    constructor() { }

    ngOnInit(): void { }
}

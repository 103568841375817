import { Component, Input, OnInit } from '@angular/core';
import { StructureChildSection } from 'src/app/models/json-data-structure/structure-child-section';
import { StructureSection } from 'src/app/models/json-data-structure/structure-section';
import { SectionDesignComponent } from 'src/app/section-structures/decorators/section-design-style.decorator';

@Component({
    selector: 'ss-cards-background',
    templateUrl: './cards-background.component.html',
    styleUrls: ['./cards-background.component.scss']
})
@SectionDesignComponent("cards-1")
export class SSCardsBackgroundComponent implements OnInit {
    @Input()
    section: StructureSection;

    @Input()
    colSize: number = 4;

    constructor() { }

    ngOnInit(): void { }
}

<div class="container">
    <h1 class="text-center mb-5 mt-5">{{section.title}}</h1>
</div>
<mdb-carousel [isControls]="true" [animation]="'slide'" [interval]="5000">
    <mdb-carousel-item *ngFor="let icr of imageChildRows">
        <div class="container">
            <div class="row">
                <div class="col-md-4 mb-5 text-center" *ngFor="let item of icr.childSection">
                    <!--Card image-->
                    <img class="img-fluid" [src]="item.backgroundURL">
                    <div class="card-body">
                        <h5>
                            {{item.title}}
                        </h5>
                        <div [innerHTML]="item.content">

                        </div>
                        <a [href]="item.navigateToLink" target="_blank">
                            <button mdbBtn color="primary">Ver más...</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </mdb-carousel-item>
</mdb-carousel>
<div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">Escribinos tu consulta</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body mx-3">
    <div class="md-form mb-5">
        <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon>
        <input type="text" id="form34" class="form-control" mdbInput [formControl]="contactFormModalName">
        <label for="form34">Nombre</label>
        <mdb-error *ngIf="contactFormModalName.invalid && (contactFormModalName.dirty || contactFormModalName.touched)">
            El campo es requerido
        </mdb-error>
    </div>

    <div class="md-form mb-5">
        <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
        <input type="email" id="email" class="form-control" mdbInput [formControl]="contactFormModalEmail">
        <label for="email">Email</label>
        <mdb-error
            *ngIf="contactFormModalEmail.invalid && (contactFormModalEmail.dirty || contactFormModalEmail.touched)">
            Ingrese un email válido
        </mdb-error>
    </div>

    <div class="md-form mb-5">
        <mdb-icon fas icon="tag" class="prefix grey-text"></mdb-icon>
        <input type="text" id="form32" class="form-control" mdbInput [formControl]="contactFormModalSubject">
        <label for="form32">Asunto</label>
        <mdb-error
            *ngIf="contactFormModalSubject.invalid && (contactFormModalSubject.dirty || contactFormModalSubject.touched)">
            El campo es requerido
        </mdb-error>
    </div>

    <div class="md-form">
        <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
        <textarea type="text" id="form8" class="md-textarea form-control" rows="1" mdbInput
            [formControl]="contactFormModalMessage"></textarea>
        <label for="form8">Mensaje</label>
        <mdb-error
            *ngIf="contactFormModalMessage.invalid && (contactFormModalMessage.dirty || contactFormModalMessage.touched)">
            El campo es requerido
        </mdb-error>
    </div>
    <div class="d-flex justify-content-center">
        <button mdbBtn color="primary" class="waves-light" mdbWavesEffect (click)="send()"
            [disabled]="loading ||
        (!contactFormModalName.valid || !contactFormModalEmail.valid || !contactFormModalSubject.valid || !contactFormModalMessage.valid)">
            Enviar
            <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="loading"></i>
            <mdb-icon far icon="paper-plane" class="ml-1" *ngIf="!loading"></mdb-icon>
        </button>
    </div>
</div>
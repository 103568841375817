import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingSectionComponent } from './components/sections/landing-section/landing-section.component';
import { CardsSectionComponent } from './components/cards-section/cards-section.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AmdbeUiModule } from "custom_modules/amdb-extensions/ng-amdbe/projects/amdbe-ui/src/lib/amdbe-ui.module"
import { ServicesSectionComponent } from './components/sections/services-section/services-section.component';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { TechnologiesSectionComponent } from './components/sections/technologies-section/technologies-section.component';

import { NgwWowModule } from 'ngx-wow';
import { FooterComponent } from './components/footer/footer.component';
import { ScrollSpyService } from 'custom_modules/amdb-extensions/ng-amdbe/projects/amdbe-ui/src/lib/scroll-spy/services/scroll-spy.service';
import { OurValuesSectionComponent } from './components/sections/our-values-section/our-values-section.component';
import { ContactUsSectionComponent } from './components/sections/contact-us-section/contact-us-section.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SectionComponent } from './components/section/section.component';
import { SessionStructureModule } from './section-structures/session-structure.module';
import { PrimaryBtnComponent } from './components/primary-btn/primary-btn.component';
import { SharedModule } from './shared-module/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SectionTitleComponent,

    //Sections
    PrimaryBtnComponent,
    LandingSectionComponent,
    ServicesSectionComponent,
    TechnologiesSectionComponent,
    OurValuesSectionComponent,
    ContactUsSectionComponent,

    FooterComponent,
    SectionComponent,

    CardsSectionComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgwWowModule,
    AmdbeUiModule,
    SharedModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    SessionStructureModule,
  ],
  providers: [
    ScrollSpyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {
    controllerURL = `${environment.apiURL}/download`;

    constructor(private httpClient: HttpClient) {
    }

    async sendEmail(name: string, email: string, emailTemplateId: string) {
        await this.httpClient.post(`${this.controllerURL}/send-mail`, {
            name: name,
            email: email,
            emailTemplateId: emailTemplateId
        }).toPromise();
    }
}
<app-navbar #navbar *ngIf="jsonDataFile" [structureDataJson]="jsonDataFile"></app-navbar>
<!-- <app-landing-section scrollSpy="section1"></app-landing-section> -->

<div class="loader" *ngIf="!jsonDataFile">Loading...</div>

<div class="mb-5" *ngIf="jsonDataFile">
  <div [scrollSpy]="section.sectionId" *ngFor="let section of jsonDataFile.sections">
    <ss-site-section [section]="section"></ss-site-section>
    <!-- <app-section [section]="section">
        </app-section> -->
  </div>
  <!-- <div scrollSpy="section2">
    <app-services-section></app-services-section>
  </div>
  <hr scrollSpy="section3">
  <app-our-values-section></app-our-values-section>
  <hr scrollSpy="section4"> -->
</div>

<app-footer *ngIf="jsonDataFile"></app-footer>
<!-- <router-outlet></router-outlet> -->
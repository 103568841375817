import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-our-values-section',
    templateUrl: 'our-values-section.component.html',
    styleUrls: ['our-values-section.component.scss']
})

export class OurValuesSectionComponent implements OnInit {
    constructor() { }

    ngOnInit() { }

   
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StructureDataJson } from '../models/json-data-structure/structure-data-json';
import acrelaciones from "../../assets/acrelaciones.json"
@Injectable({
    providedIn: 'root'
})
export class SiteService {
    resourceUrl: string = `${environment.apiURL}/site`;

    structureDataJson: StructureDataJson;

    constructor(private http: HttpClient) {
    }

    async getJsonSiteFile(): Promise<StructureDataJson> {

        // if (!environment.production) {
        //     return acrelaciones;
        // }

        if (!this.structureDataJson) {
            this.structureDataJson = await this.http.get<StructureDataJson>(`${this.resourceUrl}/get-json-site-file`).toPromise();
        }

        return this.structureDataJson;
    }
}
import { Injectable } from "@angular/core";

@Injectable()
export class SectionDesignComponentResolver {
    static componentMapper: Map<string, any> = new Map<string, any>();

    static getComponent(key: string) {
        let c = this.componentMapper.get(key);
        return c;
    }

    static addComponent(key: string, type: any) {
        this.componentMapper.set(key, type);
    }
}
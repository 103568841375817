import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SSCommonLandingComponent } from './components/landing-sections/common-landing/common-landing.component';
import { SSCardsBackgroundComponent } from './components/cards-sections/cards-background/cards-background.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { SSSiteSectionComponent } from './components/site-section/site-section.component';
import { SectionDesignComponentResolver } from './services/decorator.service';
import { SSSimpleSectionComponent } from './components/simple-sections/simple-section/simple-section.component';
import { SSPostCardComponent } from './components/cards-sections/post-card/post-card.component';
import { SSTestimonialSlideComponent } from './components/slides/testimonial-slide/testimonial-slide.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { SSTestimonialSimpleComponent } from './components/testimonial/simple/testimonial-simple.component';
import { SSIconComponent } from './components/shared/icon/icon.component';
import { SSCardSimpleComponent } from './components/cards-sections/card-simple/card-simple.component';
import { SharedModule } from '../shared-module/shared.module';
import { SSPopupMailDownloadComponent } from './components/download-sections/popup-mail-download/popup-mail-download.component';
import { FormsModule } from '@angular/forms';
import { SSSmallLandingComponent } from './components/landing-sections/small-landing/small-landing.component';

@NgModule({
    declarations: [
        SSCommonLandingComponent,
        SSSmallLandingComponent,
        SSSimpleSectionComponent,
        SSCardsBackgroundComponent,
        SSPostCardComponent,
        SSSiteSectionComponent,
        SSTestimonialSlideComponent,
        SSTestimonialSimpleComponent,
        SSIconComponent,
        SSCardSimpleComponent,
        SSPopupMailDownloadComponent,
    ],
    imports: [CommonModule,
        FormsModule,
        IvyCarouselModule,
        SharedModule,
        MDBBootstrapModule],
    exports: [
        SSSiteSectionComponent,
        SSCommonLandingComponent,
        SSCardsBackgroundComponent,],
    providers: [
        SectionDesignComponentResolver,
    ],
})
export class SessionStructureModule { }


import { NgModule } from '@angular/core';
import { AmdbeUiComponent } from './amdbe-ui.component';

import { MDBBootstrapModule } from "angular-bootstrap-md";
import { ScrollSpyDirective } from './scroll-spy/directives/scroll-spy.directive';
import { ScrollSpyService } from './scroll-spy/services/scroll-spy.service';

@NgModule({
  declarations: [
    AmdbeUiComponent,

    //Directives
    ScrollSpyDirective,
  ],
  imports: [
    MDBBootstrapModule
  ],
  exports: [AmdbeUiComponent,
    ScrollSpyDirective,
  ]
})
export class AmdbeUiModule { }

import { Component, Input, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { StructureChildSection } from 'src/app/models/json-data-structure/structure-child-section';
import { StructureSection } from 'src/app/models/json-data-structure/structure-section';
import { SectionDesignComponent } from 'src/app/section-structures/decorators/section-design-style.decorator';
import { ISectionComponent } from 'src/app/section-structures/models/isection-component';
import { DownloadService } from 'src/app/services/download.service';
import { SharedAlertDialogComponent } from 'src/app/shared-module/components/alert-dialog/alert-dialog.component';

@Component({
    selector: 'ss-popup-mail-download',
    templateUrl: './popup-mail-download.component.html',
    styleUrls: ['./popup-mail-download.component.scss']
})
@SectionDesignComponent("popup-mail-download")
export class SSPopupMailDownloadComponent implements OnInit, ISectionComponent {
    @Input()
    section: StructureSection;

    email: string;
    name: string;

    selectedChild: StructureChildSection;

    loading: boolean;

    constructor(private downloadService: DownloadService,
        private modalService: MDBModalService) { }

    ngOnInit(): void { }

    async sendEmail(childSection: StructureChildSection, basicModal: any) {
        this.loading = true;
        try {
            await this.downloadService.sendEmail(this.name, this.email, childSection.emailTemplateId);
            this.modalService.show(SharedAlertDialogComponent, {
                data: {
                    content: "Se envió un correo a tu casilla con el link de descarga."
                }
            });
            basicModal.hide();
        } catch {
            alert("Ha ocurrido un error al enviar el correo.");
        }
        finally {
            this.loading = false;
        }

    }
}

import { Component, Input, OnInit } from '@angular/core';
import { ScrollSpyService } from 'custom_modules/amdb-extensions/ng-amdbe/projects/amdbe-ui/src/lib/scroll-spy/services/scroll-spy.service';
import * as copy from 'copy-to-clipboard';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ContactUsSectionComponent } from "../sections/contact-us-section/contact-us-section.component";
import { StructureDataJson } from 'src/app/models/json-data-structure/structure-data-json';

@Component({
    selector: 'app-navbar',
    templateUrl: 'navbar.component.html',
    styleUrls: ['navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    currentSection: string;

    @Input()
    structureDataJson: StructureDataJson;

    constructor(public scrollSpyService: ScrollSpyService,
        
        public modalService: MDBModalService) { }

    ngOnInit() { }

    openModal() {
        this.modalService.show(ContactUsSectionComponent, {
            backdrop: 'static'
        });
    }

    copyAddress() {
        copy('acrelacioneslaborales@gmail.com');
    }
}
import { Directive, Injectable, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';
import { ScrollSpyService } from '../services/scroll-spy.service';

@Directive({
    selector: '[scrollSpy]'
})
export class ScrollSpyDirective {
    @Input()
    public scrollSpy: string;

    @Output() public sectionChange = new EventEmitter<string>();
    private currentSection: string;

    constructor(private _el: ElementRef,
        private scrollSpyService: ScrollSpyService) {
    }

    ngOnInit(): void {
        this.scrollSpyService.addSpy({
            id: this.scrollSpy, offsetTop: this._el.nativeElement.offsetTop,
            elementRef: this._el
        });
        document.addEventListener("scroll", () => this.elementIsOnTop());
    }

    elementIsOnTop() {
        let spies = this.scrollSpyService.confiredSpy.filter(x => (<HTMLElement>x.elementRef.nativeElement).offsetTop < window.pageYOffset + (window.outerHeight / 3));

        let currentSpy = spies[spies.length - 1];

        if (currentSpy) {
            if (this.scrollSpyService.currentSection != currentSpy.id) {
                this.scrollSpyService.currentSection = currentSpy.id;
            }
        }
    }
}
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { window } from 'rxjs/operators';
import { StructureSection } from 'src/app/models/json-data-structure/structure-section';

@Component({
    selector: 'app-landing-section',
    templateUrl: 'landing-section.component.html',
    styleUrls: ['landing-section.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class LandingSectionComponent implements OnInit {
    @Input()
    section: StructureSection;

    constructor() { }

    ngOnInit() { 
        this.section;
    }

    mobileSize() {
        return document.body.clientWidth < 768;
    }
}
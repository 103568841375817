import { Component, Input, OnInit } from '@angular/core';
import { Icon } from 'src/app/section-structures/models/icon';

@Component({
    selector: 'ss-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class SSIconComponent implements OnInit {
    @Input()
    icon: Icon;

    constructor() { }

    ngOnInit(): void { }
}

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'primary-btn',
    templateUrl: './primary-btn.component.html',
    styleUrls: ['./primary-btn.component.scss']
})
export class PrimaryBtnComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}

<div *ngIf="section" class="div-small-landing wow fadeIn" data-wow-delay="0.1s" style="background-size: cover;"
    [ngStyle]="{'background-color': section.backgroundColor}">
    <div class="col-md-12 text-center wow fadeInDown" data-wow-delay="0.1s">
        <h2 class="h1 h1-reponsive white-text font-up font-bold mb-3 wow fadeInDown" data-wow-delay="0.1s">
            <strong>{{section.title}}</strong>
        </h2>
        <div class="wow fadeInDown" data-wow-delay="0.1s">
            <hr class="hr-light mt-4">
        </div>
        <h5 class="text-white wow fadeInDown" data-wow-delay="0.1s">
            <strong>{{section.subtitle}}</strong>
        </h5>
        <div class="col-12 text-white text-center wow fadeInDown" [innerHTML]="section.content | safeHtml">
        </div>
    </div>
    <div class="text-center" style="padding-top: 30px;">
        <a class="a-btn btn btn-secondary" target="_blank"
            onclick="gtag('event', 'conversion', { 'send_to': 'AW-11253295981/PsHhCMfNmb4YEO3W_vUp', });"
            href="https://acrelacioneslaborales.empretienda.com.ar" role="button"><i class="fa fa-shopping-basket"
                aria-hidden="true"></i>
            Tienda Online</a>

        <a class="a-btn btn btn-secondary" target="_blank"
            onclick="gtag('event', 'conversion', { 'send_to': 'AW-11253295981/PsHhCMfNmb4YEO3W_vUp', });"
            href="https://www.instagram.com/ac.relacioneslaborales" role="button">
            <i class="fa-instagram fab" aria-hidden="true"></i>
            Instagram</a>
    </div>
    <!-- style="padding: 20px; border-radius: 10px; background-color: rgba(0, 0, 0, 0.616);" -->
</div>
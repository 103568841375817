<div class="row align-items-center">
    <div class="col-12 text-center wow bounceInLeft">
        <app-section-title title="NUESTRO OBJETIVO">
        </app-section-title>
    </div>
    <div class="col-12 col-lg text-center wow bounceInLeft" data-wow-delay="0.1s">
        <!--Card-->
        <mdb-icon fas icon="laptop-code fa-5x mb-3"></mdb-icon>
        <mdb-card cascade="true" wider="true">

            <!--Card image-->
            <div class="view view-cascade overlay waves-light" mdbWavesEffect>
                <a>
                    <div class="mask rgba-white-slight"></div>
                </a>
            </div>
            <!--/Card image-->

            <mdb-card-body cascade="true" class="text-center pb-0">
                <!--Card content-->
                <!--Title-->
                <h4 class="card-title">
                    <strong>HACEMOS TU SITIO</strong>
                </h4>

                <mdb-card-text>
                    Desarrollo el sitio web para tu marca con un diseño amigable para tus potenciales
                    clientes.
                </mdb-card-text>

            </mdb-card-body>
            <!--/.Card content-->
        </mdb-card>
        <!--/.Card-->
    </div>
    <div class="col-12 col-lg-auto wow bounceInLeft text-center" data-wow-delay="0.2s">
        <mdb-icon fas icon="hand-point-right fa-3x d-none d-lg-block"></mdb-icon>
        <mdb-icon fas icon="hand-point-down fa-3x d-md-block d-lg-none my-3"></mdb-icon>
    </div>
    <div class="col-12 col-lg text-center wow bounceInLeft" data-wow-delay="0.3s">
        <!--Card-->
        <mdb-icon fas icon="chart-line fa-5x mb-3"></mdb-icon>

        <mdb-card cascade="true" wider="true">

            <!--Card image-->
            <div class="view view-cascade overlay waves-light" mdbWavesEffect>
                <a>
                    <div class="mask rgba-white-slight"></div>
                </a>
            </div>
            <!--/Card image-->

            <mdb-card-body cascade="true" class="text-center pb-0">
                <!--Card content-->
                <!--Title-->
                <h4 class="card-title">
                    <strong>POTENCIA TU MARCA</strong>
                </h4>
                <!-- <h5 class="indigo-text">
                    <strong>Photographer</strong>
                </h5> -->

                <mdb-card-text>
                    El sitio web adecuado te ayuda a ampliar tu cartera de clientes y a captar su atención por encima de
                    la competencia.
                </mdb-card-text>


            </mdb-card-body>
            <!--/.Card content-->


        </mdb-card>
        <!--/.Card-->
    </div>
    <div class="col-12 col-lg-auto wow bounceInLeft text-center" data-wow-delay="0.4s">
        <mdb-icon fas icon="hand-point-right fa-3x d-none d-lg-block"></mdb-icon>
        <mdb-icon fas icon="hand-point-down fa-3x d-md-block d-lg-none my-3"></mdb-icon>
    </div>
    <div class="col-12 col-lg wow bounceInLeft text-center" data-wow-delay="0.5s">
        <mdb-icon fas icon="money-bill-alt fa-5x mb-3"></mdb-icon>
        <!--Card-->
        <mdb-card cascade="true" wider="true">

            <!--Card image-->
            <div class="wow view view-cascade overlay waves-light" rounded mdbWavesEffect>
                <a>
                    <div class="mask rgba-white-slight"></div>
                </a>
            </div>
            <!--/Card image-->

            <mdb-card-body cascade="true" class="text-center pb-0">
                <!--Card content-->
                <!--Title-->
                <h4 class="card-title">
                    <strong>MEJORA TUS INGRESOS</strong>
                </h4>
                <!-- <h5 class="indigo-text">
                    <strong>Photographer</strong>
                </h5> -->

                <mdb-card-text>
                    Una marca mas potenciada, generará mayores ingresos en tu empresa
                </mdb-card-text>

            </mdb-card-body>
            <!--/.Card content-->




        </mdb-card>
        <!--/.Card-->
    </div>
</div>
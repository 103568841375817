<header class="intro-header">

    <!--Navbar-->
    <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark fixed-top">
        <mdb-navbar-brand>
            <img [src]="structureDataJson.logoURL" class="mr-3" height="40" alt="" loading="lazy" />
        </mdb-navbar-brand>
        <links>
            <ul class="navbar-nav mr-auto">
                <li class="nav-item waves-light" mdbWavesEffect *ngFor="let navSection of structureDataJson.navbarItems"
                    (click)="scrollSpyService.goToSection(navSection.sectionId)"
                    [ngClass]="{ 'active': scrollSpyService.currentSection === navSection.sectionId }">
                    <a class="nav-link">{{navSection.description}}</a>
                </li>
                <!-- <li class="nav-item waves-light" mdbWavesEffect (click)="openModal()">
                    <a class="nav-link">
                        Contáctanos!</a>
                </li> -->
            </ul>
            <ul class="navbar-nav ml-auto nav-flex-icons">
                <li class="nav-item" (click)="copyAddress()" mdbTooltip="Clickea para copiar!">
                    <a class="nav-link">
                        acrelacioneslaborales@gmail.com
                        <mdb-icon fas icon="envelope"></mdb-icon>
                    </a>
                </li>
            </ul>
        </links>
    </mdb-navbar>

</header>
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavbarComponent } from './components/navbar/navbar.component';
import { JsonDataFile } from './models/json-data-file';
import { StructureDataJson } from './models/json-data-structure/structure-data-json';
import { SiteService } from './services/site.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild("navbar") navbar: ElementRef<NavbarComponent>;

  title = 'landing-template';

  jsonDataFile: StructureDataJson;

  private wowSubscription: Subscription;

  constructor(private router: Router, private wowService: NgwWowService,
    private site: SiteService, private el: ElementRef) {
    this.router.events.pipe(
      filter((event: NavigationEnd) => event instanceof NavigationEnd)
    ).subscribe(x => { this.wowService.init() });

  }

  async ngOnInit(): Promise<void> {
    // you can subscribe to WOW observable to react when an element is revealed
    this.wowSubscription = this.wowService.itemRevealed$.subscribe(
      (item: HTMLElement) => {
        // do whatever you want with revealed element
      });

    console.log(this.navbar);

    this.jsonDataFile = await this.site.getJsonSiteFile();
    console.log("jsonDataFile", this.jsonDataFile);
    this.setStyleTheme();
  }

  ngOnDestroy() {
    // unsubscribe (if necessary) to WOW observable to prevent memory leaks
    this.wowSubscription.unsubscribe();
  }

  onSectionChange(sectionId: string) {
    this.navbar.nativeElement.currentSection = sectionId;
  }

  scrollTo(section) {
    document.querySelector('#' + section)
      .scrollIntoView();
  }

  setStyleTheme() {
    let favIcon: HTMLLinkElement = document.querySelector('#page-icon');

    favIcon.href = this.jsonDataFile.logoURL;

    this.changeStyleVar('--navbar-bk-color', this.jsonDataFile.style.navbar.background);
    this.changeStyleVar('--navbar-bk-active', this.jsonDataFile.style.navbar.background);
    this.changeStyleVar('--navbar-text-color', this.jsonDataFile.style.navbar.text);

    this.changeStyleVar('--title-color', this.jsonDataFile.style.titleColor);
    this.changeStyleVar('--subtitle-color', this.jsonDataFile.style.subtitleColor);
    // this.changeStyleVar('--third-title-color', this.jsonDataFile.style.thirdTitleColor);
    this.changeStyleVar('--text-color', this.jsonDataFile.style.contentColor);

    this.changeStyleVar('--btn-bk-primary', this.jsonDataFile.style.buttonPrimary.background);
    this.changeStyleVar('--btn-text-primary', this.jsonDataFile.style.buttonPrimary.text);

    this.changeStyleVar('--btn-bk-secondary', this.jsonDataFile.style.buttonSecondary.background);
    this.changeStyleVar('--btn-text-secondary', this.jsonDataFile.style.buttonSecondary.text);

  }

  changeStyleVar(name: string, value: string) {
    document.documentElement.style.setProperty(name, value);
    // (this.el.nativeElement as HTMLElement).style.setProperty(name, value);
  }
}

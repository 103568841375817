import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MDBModalRef } from 'angular-bootstrap-md';
import { ContactFormService } from 'src/app/services/contact-form.service';

@Component({
    selector: 'app-contact-us-section',
    templateUrl: 'contact-us-section.component.html',
    encapsulation: ViewEncapsulation.None
})

export class ContactUsSectionComponent implements OnInit {
    @ViewChild("frame") frame: ElementRef<any>;
    loading: boolean = false;

    validatingForm: FormGroup;

    ngOnInit() {
        this.validatingForm = new FormGroup({
            contactFormModalName: new FormControl('', Validators.required),
            contactFormModalEmail: new FormControl('', [Validators.email, Validators.required]),
            contactFormModalSubject: new FormControl('', Validators.required),
            contactFormModalMessage: new FormControl('', Validators.required)
        });
    }

    constructor(public modalRef: MDBModalRef,
        private contactForm: ContactFormService) {

    }

    hide() {
        this.modalRef.hide();
    }

    async send() {
        this.loading = true;
        try {
            await this.contactForm.sendNotification(this.contactFormModalName.value,
                this.contactFormModalEmail.value, this.contactFormModalSubject.value, this.contactFormModalMessage.value);
            this.modalRef.hide();
        }
        catch {
            alert("Ha ocurrido un error al enviar su consulta");
        }
        finally {
            this.loading = false;
        }
    }

    get contactFormModalName() {
        return this.validatingForm.get('contactFormModalName');
    }

    get contactFormModalEmail() {
        return this.validatingForm.get('contactFormModalEmail');
    }

    get contactFormModalSubject() {
        return this.validatingForm.get('contactFormModalSubject');
    }

    get contactFormModalMessage() {
        return this.validatingForm.get('contactFormModalMessage');
    }
}
<div class="container mb-5 mt-5">
    <h1 class="text-center mb-5">{{section.title}}</h1>
</div>
<mdb-carousel [isControls]="true" [animation]="'slide'" [interval]="5000">
    <mdb-carousel-item *ngFor="let icr of imageChildRows">
        <div class="container">
            <div class="row">
                <div class="col-md-4 mb-4" *ngFor="let item of icr.childSection; let i = index">
                    <div class="testimonial item-{{i % 3}}">
                        <div class="testimonial-body">
                            <h4 class="text-center">
                                <mdb-icon fas icon="comment-dots" size="2x"></mdb-icon><br>
                            </h4>
                            <div [innerHTML]="item.content">
                            </div>
                        </div>
                        <div class="testimonial-footer">
                            {{item.title}}
                            <small> | {{item.subtitle}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mdb-carousel-item>
</mdb-carousel>
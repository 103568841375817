<div class="container mb-3 mt-5">
    <h1 class="text-center">{{section.title}}</h1>
</div>
<mdb-carousel [isControls]="true" [animation]="'slide'" [interval]="5000">
    <mdb-carousel-item *ngFor="let icr of imageChildRows">
        <div class="container">
            <div class="row">
                <div class="col-md-4 mb-4 text-center" *ngFor="let item of icr.childSection; let i = index">
                    <hr>
                    <mdb-icon fas icon="quote-left" size="2x" class="orange-text"></mdb-icon>
                    <div class="mt-0 mb-3">
                        <ss-icon *ngIf="item.icon" [icon]="item.icon"></ss-icon>
                    </div>
                    <div [innerHTML]="item.content">
                    </div>
                    <h5>
                        {{item.title}}
                    </h5>
                </div>
            </div>
        </div>
    </mdb-carousel-item>
</mdb-carousel>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { SharedAlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { NgwWowModule } from 'ngx-wow';
import { AmdbeUiModule } from 'custom_modules/amdb-extensions/ng-amdbe/projects/amdbe-ui/src/public-api';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';

@NgModule({
    declarations: [
        SharedAlertDialogComponent,
        SafeHtmlPipe,
    ],
    imports: [CommonModule,
        NgwWowModule,
        AmdbeUiModule,
        MDBBootstrapModule],
    exports: [
        SharedAlertDialogComponent,
        SafeHtmlPipe
    ],
    providers: [],
})
export class SharedModule { }
<div class="container mt-5">
    <h1 class="text-center">{{section.title}}</h1>
    <h2 class="text-center mb-5">{{section.subtitle}}</h2>
    <div class="row mt-3" *ngFor="let item of section.childSections">
        <div class="col-md-3 text-center">
            <img [src]="item.backgroundURL" class="img-fluid">
        </div>
        <div class="col-md-9">
            <h3>{{item.title}}</h3>
            <h4>{{item.subtitle}}</h4>
            <div [innerHTML]="item.content"></div>

            <button mdbBtn color="primary" (click)="this.selectedChild = item; basicModal.show()">Descargar</button>
        </div>
    </div>
</div>

<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form #sendEmailForm="ngForm">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                        <span aria-hidden="true">×</span>
                    </button>
                    <h4 class="modal-title w-100" id="myModalLabel">Descargar {{selectedChild?.title}}</h4>
                </div>
                <div class="modal-body">
                    Gracias por tu interés en mi {{selectedChild?.title}}.<br>
                    Te envío el link de descarga a tu correo electrónico.
                    <div class="md-form">
                        <input mdbInput [(ngModel)]="name" type="search" name="name" id="form1" class="form-control"
                            autocomplete="off" required>
                        <label for="form1" class="">Ingresá tu nombre</label>
                    </div>
                    <div class="md-form">
                        <input mdbInput [(ngModel)]="email" type="search" name="email" id="form1" class="form-control"
                            autocomplete="off" required email>
                        <label for="form1" class="">Ingresa tu email</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
                        (click)="basicModal.hide()" mdbWavesEffect>Cancelar</button>
                    <button type="button" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect
                        (click)="sendEmail(selectedChild, basicModal)" [disabled]="loading || sendEmailForm.invalid">
                        Enviar
                        <i *ngIf="loading" class="fa fa-spinner fa-spin ml-1" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
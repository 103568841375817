import { Component, Input, OnInit } from '@angular/core';
import { StructureChildSection } from 'src/app/models/json-data-structure/structure-child-section';
import { StructureSection } from 'src/app/models/json-data-structure/structure-section';
import { SectionDesignComponent } from 'src/app/section-structures/decorators/section-design-style.decorator';
import { ISectionComponent } from 'src/app/section-structures/models/isection-component';

@Component({
    selector: 'ss-post-card',
    templateUrl: './post-card.component.html',
    styleUrls: ['./post-card.component.scss']
})
@SectionDesignComponent("post-card")
export class SSPostCardComponent implements OnInit, ISectionComponent {
    @Input()
    section: StructureSection;

    imageChildRows: ImageChildRow[] = new Array<ImageChildRow>();

    constructor() { }

    ngOnInit(): void {
        let icr: ImageChildRow;
        for (let i = 0; i < this.section.childSections.length; i++) {
            if (i % 3 == 0) {
                icr = new ImageChildRow();
                this.imageChildRows.push(icr);
            }
            icr.childSection.push(this.section.childSections[i]);
        }
        console.log(this.imageChildRows);
    }
}

class ImageChildRow {
    childSection: StructureChildSection[] = new Array<StructureChildSection>();
}
<section id="nuestros-valores" class="mb-5  text-center wow">
    <app-section-title title="Nuestros Valores"></app-section-title>
    <!--Grid row-->
    <div class="row align-items-center">
        <div class="col col-md-4 text-center">
            <div class="card-info card card-image circle-card wow fadeIn">

                <!-- Content -->
                <div class="text-white text-center d-flex align-items-center  rgba-black-strong py-5 px-4">
                    <div style="width:100%;">
                        <h5 class="darken-1 mt-3"><i class="green-text fas fa-users fa-3x"></i></h5>
                        <h5 class="card-title pt-2 text-center"><strong>TRABAJO EN EQUIPO</strong></h5>
                        <!-- <a class="btn btn-pink"><i class="fas fa-clone left"></i> View project</a> -->
                    </div>
                </div>
            </div>
            <!-- Card -->
        </div>
        <div class="col col-md-4 text-center">
            <div class="card-info card card-image circle-card wow fadeIn">

                <!-- Content -->
                <div class="text-white text-center d-flex align-items-center  rgba-black-strong py-5 px-4">
                    <div style="width:100%;">
                        <h5 class="darken-1 mt-3"><i class="text-primary fas fa-address-book fa-3x"></i></h5>
                        <h5 class="card-title pt-2 text-center"><strong>ORGANIZACION</strong></h5>
                        <!-- <a class="btn btn-pink"><i class="fas fa-clone left"></i> View project</a> -->
                    </div>
                </div>
            </div>
            <!-- Card -->
        </div>
        <div class="col col-md-4 text-center">
            <div class="card-info card card-image circle-card wow fadeIn">

                <!-- Content -->
                <div class="text-white text-center d-flex align-items-center  rgba-black-strong py-5 px-4">
                    <div style="width:100%;">
                        <h5 class="darken-1 mt-3"><i class="red-text fas fa-user-tie fa-3x"></i></h5>
                        <h5 class="card-title pt-2 text-center"><strong>INTEGRIDAD</strong></h5>
                        <!-- <a class="btn btn-pink"><i class="fas fa-clone left"></i> View project</a> -->
                    </div>
                </div>
            </div>
            <!-- Card -->
        </div>
        <div class="col col-md-4 text-center">
            <div class="card-info card card-image circle-card wow fadeIn">

                <!-- Content -->
                <div class="text-white text-center d-flex align-items-center  rgba-black-strong py-5 px-4">
                    <div style="width:100%;">
                        <h5 class="darken-1 mt-3"><i class="text-warning fas fa-edit fa-3x"></i></h5>
                        <h6 class="card-title pt-2 text-center"><strong>RESPONSABILIDAD</strong></h6>
                        <!-- <a class="btn btn-pink"><i class="fas fa-clone left"></i> View project</a> -->
                    </div>
                </div>
            </div>
            <!-- Card -->
        </div>
        <div class="col col-md-4 text-center">
            <div class="card-info card card-image circle-card wow fadeIn">

                <!-- Content -->
                <div class="text-white text-center d-flex align-items-center darken-4 rgba-black-strong py-5 px-4">
                    <div style="width:100%;">
                        <h5 class="darken-1 mt-3"><i class="purple-text fas fa-user-lock fa-3x"></i></h5>
                        <h6 class="card-title pt-2 text-center"><strong>CONFIDENCIALIDAD</strong></h6>
                        <!-- <a class="btn btn-pink"><i class="fas fa-clone left"></i> View project</a> -->
                    </div>
                </div>
            </div>
            <!-- Card -->
        </div>
        <div class="col col-md-4 text-center">
            <div class="card-info card card-image circle-card wow fadeIn">

                <!-- Content -->
                <div class="text-white text-center d-flex align-items-center  rgba-black-strong py-5 px-4">
                    <div style="width:100%;">
                        <h5 class="darken-1 mt-3"><i class="fas fa-fist-raised fa-3x"></i></h5>
                        <h5 class="card-title pt-2 text-center"><strong>RESPETO</strong></h5>
                        <!-- <a class="btn btn-pink"><i class="fas fa-clone left"></i> View project</a> -->
                    </div>
                </div>
            </div>
            <!-- Card -->
        </div>

    </div>
</section>
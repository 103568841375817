<div class="container">
    <h1 class="text-center mb-5 mt-5">{{section.title}}</h1>
    <div class="row">
        <div class="col-md-{{12 / section.childSections.length}} wow fadeIn"
            *ngFor="let childSection of section.childSections">
            <!-- Card -->
            <div class="text-center">
                <ss-icon [icon]="childSection.icon"></ss-icon>
                <h2 class="pt-3">
                    {{childSection.title}}
                </h2>
                <div [innerHtml]="childSection.content">
                    
                </div>
            </div>
            <!-- Card -->
        </div>
    </div>
</div>
import { Injectable, ElementRef } from '@angular/core';

@Injectable()
export class ScrollSpyService {
    private _configuredSpy: Spy[] = new Array<Spy>();
    currentSection: string;

    public get confiredSpy(): Array<Spy> {
        return this._configuredSpy;
    }

    constructor() { }

    addSpy(spy: Spy) {
        this._configuredSpy.push(spy);

        this._configuredSpy = this._configuredSpy.sort(x => x.offsetTop);
    }

    goToSection(id: string, navbarHeight = 80) {
        debugger;
        let spy = this.confiredSpy.find(x => x.id == id);
        if (!spy) return;
        window.scrollTo({
            behavior: "smooth",
            top: (<HTMLElement>spy.elementRef.nativeElement).offsetTop - navbarHeight,
        });
    }
}

export class Spy {
    id: string;
    offsetTop: number;
    elementRef: ElementRef;
}
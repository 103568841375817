<div class="container">
    <h1 class="text-center mb-5 mt-5">{{section.title}}</h1>
    <div class="row">
        <div class="col-md-{{12 / section.childSections.length}} wow fadeIn"
            *ngFor="let childSection of section.childSections">
            <!-- Card -->
            <mdb-card [style]="'background-image: url(' + childSection.backgroundURL + ')'"
                class="card-image mb-3 card-info">
                <!-- Content -->
                <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4">
                    <div>
                        <h5 class="text-primary">
                            <mdb-icon fas icon="sitemap"></mdb-icon>
                            {{childSection.title}}
                        </h5>
                        <h3 class="card-title pt-2"><strong>{{childSection.subtitle}}</strong></h3>
                        <div [innerHTML]="childSection.content">
                            
                        </div>

                    </div>
                </div>
                <!-- Content -->
            </mdb-card>
            <!-- Card -->
        </div>
    </div>
</div>
<div class="container mb-3 mt-5">
    <div class="row align-items-center">
        <div class="col text-center">
            <h1 *ngIf="section.title">{{section.title}}</h1>
            <h2 class="mb-4">{{section.subtitle}}</h2>
        </div>
    </div>
    <div class="row align-items-center">
        <div class="col-md-{{12 / section.childSections.length}}" *ngFor="let child of section.childSections">
            <h1 *ngIf="child.title">{{child.title}}</h1>
            <h2 *ngIf="child.subtitle">{{child.subtitle}}</h2>
            <div *ngIf="child.content" [innerHTML]="child.content"></div>
            <div class="text-center" *ngIf="child.backgroundURL">
                <img class="img-fluid" [src]="child.backgroundURL">
            </div>
        </div>
    </div>
</div>
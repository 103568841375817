import { Component, Input, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';

@Component({
    selector: 'shared-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss']
})
export class SharedAlertDialogComponent implements OnInit {
    @Input()
    content: string;

    constructor(public modalRef: MDBModalRef) { }

    ngOnInit(): void { }
}

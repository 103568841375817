import { Component, Input, OnInit } from '@angular/core';
import { ChildSection, Section } from 'src/app/models/json-data-file';
import { StructureSection } from 'src/app/models/json-data-structure/structure-section';
import { ISectionComponent } from 'src/app/section-structures/models/isection-component';

@Component({
    selector: 'app-section',
    templateUrl: './section.component.html',
    styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit, ISectionComponent {

    @Input()
    section: StructureSection;

    constructor() { }

    ngOnInit(): void { 
        this.section;
    }
}

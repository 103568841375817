<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ content }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect
            (click)="modalRef.hide()">Aceptar</button>
    </div>
</div>
<div class="row">
    <!--Grid row-->
    <div class="col-sm-4">
        <!-- Card -->
        <mdb-card
            style="background-image: url('https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20%2814%29.jpg')"
            class="card-image mb-3 mx-2">
            <!-- Content -->
            <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4">
                <div>
                    <h5 class="pink-text">
                        <mdb-icon fas icon="chart-pie"></mdb-icon>
                        Marketing
                    </h5>
                    <h3 class="card-title pt-2"><strong>This is card title</strong></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat fugiat, laboriosam,
                        voluptatem, optio vero odio nam sit officia accusamus minus error nisi architecto nulla
                        ipsum dignissimos. Odit sed qui, dolorum!.
                    </p>
                    <a mdbBtn color="pink" class="waves-light" mdbWavesEffect>
                        <mdb-icon far icon="clone" class="left"></mdb-icon>
                        View project
                    </a>
                </div>
            </div>
            <!-- Content -->
        </mdb-card>
        <!-- Card -->
    </div>
    <div class="col-sm-4">
        <!-- Card -->
        <mdb-card
            style="background-image: url('https://mdbootstrap.com/img/Photos/Horizontal/City/6-col/img%20(47).jpg')"
            class="card-image mb-3 mx-2">
            <!-- Content -->
            <div class="text-white text-center d-flex align-items-center rgba-indigo-strong py-5 px-4">
                <div>
                    <h5 class="orange-text">
                        <mdb-icon fas icon="desktop"></mdb-icon>
                        Software
                    </h5>
                    <h3 class="card-title pt-2"><strong>This is card title</strong></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat fugiat, laboriosam,
                        voluptatem, optio vero odio nam sit officia accusamus minus error nisi architecto nulla
                        ipsum dignissimos. Odit sed qui, dolorum!.
                    </p>
                    <a mdbBtn color="deep-orange" class="waves-light" mdbWavesEffect>
                        <mdb-icon far icon="clone" class="left"></mdb-icon>
                        View project
                    </a>
                </div>
            </div>
            <!-- Content -->
        </mdb-card>
        <!-- Card -->
    </div>
    <div class="col-sm">
        <mdb-card>
            <!--Card image-->
            <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg"
                alt="Card image cap">
            </mdb-card-img>
            <!--Card content-->
            <mdb-card-body>

                <!--Title-->
                <mdb-card-title>
                    <h4>Card Title</h4>
                </mdb-card-title>

                <!--Text-->
                <mdb-card-text> Some quick example text to build on the card title and make up the bulk of the card's
                    content.
                </mdb-card-text>

                <a href="#" mdbBtn color="primary" mdbWavesEffect>Button</a>
            </mdb-card-body>
        </mdb-card>
    </div>
    <div class="col-sm">
        <mdb-card>
            <!--Card image-->
            <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg"
                alt="Card image cap">
            </mdb-card-img>
            <!--Card content-->
            <mdb-card-body>

                <!--Title-->
                <mdb-card-title>
                    <h4>Card Title</h4>
                </mdb-card-title>

                <!--Text-->
                <mdb-card-text> Some quick example text to build on the card title and make up the bulk of the card's
                    content.
                </mdb-card-text>

                <a href="#" mdbBtn color="primary" mdbWavesEffect>Button</a>
            </mdb-card-body>
        </mdb-card>
    </div>
    <div class="col-sm">
        <mdb-card>
            <!--Card image-->
            <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg"
                alt="Card image cap">
            </mdb-card-img>
            <!--Card content-->
            <mdb-card-body>

                <!--Title-->
                <mdb-card-title>
                    <h4>Card Title</h4>
                </mdb-card-title>

                <!--Text-->
                <mdb-card-text> Some quick example text to build on the card title and make up the bulk of the card's
                    content.
                </mdb-card-text>

                <a href="#" mdbBtn color="primary" mdbWavesEffect>Button</a>
            </mdb-card-body>
        </mdb-card>
    </div>
    <div class="col-sm">
        <mdb-card>
            <!--Card image-->
            <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg"
                alt="Card image cap">
            </mdb-card-img>
            <!--Card content-->
            <mdb-card-body>

                <!--Title-->
                <mdb-card-title>
                    <h4>Card Title</h4>
                </mdb-card-title>

                <!--Text-->
                <mdb-card-text> Some quick example text to build on the card title and make up the bulk of the card's
                    content.
                </mdb-card-text>

                <a href="#" mdbBtn color="primary" mdbWavesEffect>Button</a>
            </mdb-card-body>
        </mdb-card>
    </div>
    <div class="col-sm">
        <mdb-card>
            <!--Card image-->
            <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg"
                alt="Card image cap">
            </mdb-card-img>
            <!--Card content-->
            <mdb-card-body>

                <!--Title-->
                <mdb-card-title>
                    <h4>Card Title</h4>
                </mdb-card-title>

                <!--Text-->
                <mdb-card-text> Some quick example text to build on the card title and make up the bulk of the card's
                    content.
                </mdb-card-text>

                <a href="#" mdbBtn color="primary" mdbWavesEffect>Button</a>
            </mdb-card-body>
        </mdb-card>
    </div>
    <div class="col-sm">
        <mdb-card>
            <!--Card image-->
            <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg"
                alt="Card image cap">
            </mdb-card-img>
            <!--Card content-->
            <mdb-card-body>

                <!--Title-->
                <mdb-card-title>
                    <h4>Card Title</h4>
                </mdb-card-title>

                <!--Text-->
                <mdb-card-text> Some quick example text to build on the card title and make up the bulk of the card's
                    content.
                </mdb-card-text>

                <a href="#" mdbBtn color="primary" mdbWavesEffect>Button</a>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>